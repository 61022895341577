#wrapper {
  height: 100%;
}

.shared-file-view-md {
  height: 100%;
  overflow-y: hidden;
}

.shared-file-view-md-header {
  background: #f4f4f7;
  height: 53px;
  border-bottom: 1px solid #e8e8e8;
  padding: 8px 16px 4px;
  justify-content: space-between;
}

.shared-file-view-md-main {
  height: calc(100% - 53px);
}

.shared-file-view-head {
  width: 950px;
  margin: 0 auto;
  height: 60px;
  display: flex;
  align-items:center;
  justify-content: space-between;
}

.shared-file-view-head h2 {
  color: #222;
  font-size: 1.4em;
  margin-bottom: 0px;
  font-weight: 400;
}

.shared-file-view-head .share-by {
  margin: 0;
}

.shared-file-view-body {
  height: calc(100% - 60px);
  padding: 30px 0 15px;
  background: #f4f4f4;
  border: 1px solid #ededed;
  overflow: auto;
}

.shared-file-view-body .md-view {
  min-height: 400px;
  padding: 70px 75px;
  border: 1px solid #ccc;
  margin: 0 auto;
  box-shadow: 0 0 6px #ccc;
  width: 950px;
  background: #fff;
  overflow: auto;
}

@media (max-width: 991.98px) {
  .shared-file-view-head {
    width: 100%;
    padding: 10px 20px;
  }
  .shared-file-view-body .md-view {
    width: 95%;
  }
  .shared-file-view-body .md-view {
    padding: 55px 50px;
  }
}

@media (max-width: 768px) {
  .shared-file-view-body .md-view {
    padding: 40px;
  }
}
