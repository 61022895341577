.seafile-comment {
  border-left: 1px solid #e6e6dd;
  display: flex;
  flex-direction: column;
  width: 29%;
}
.seafile-comment-title,
.seafile-history-title {
  border-bottom: 1px solid #e5e5e5;
  min-height: 3em;
  line-height: 3em;
  padding: 0 1em;
  display: flex;
  background-color: #fafaf9;
}
.seafile-comment-title .seafile-comment-title-text,
.seafile-history-title .seafile-history-title-text {
  width: 100%;
  text-align: center;
  font-weight: 700;
}
.seafile-comment-title .seafile-comment-title-close,
.seafile-history-title .seafile-history-title-close {
  color: #b9b9b9;
}
.seafile-comment-title .seafile-comment-title-close:hover,
.seafile-history-title .seafile-history-title-close:hover {
  color: #888;
}
.seafile-comment-toggle-resolved {
  margin-top: 45px;
  border-bottom: 1px solid #e5e5e5;
  padding: 5px 10px;
  display: flex;
  position: absolute;
  background-color: #fff;
  justify-content: space-between;
  width: 29%;
}
.seafile-comment-list {
  height: calc(100% - 40px);
  margin-top: 30px;
  overflow-y: auto;
  margin-bottom: 0;
}
.seafile-comment-list .comment-vacant {
  padding: 1em;
  text-align: center;
  list-style: none;
}
.seafile-comment-item {
  padding: 15px 10px;
  margin-bottom: 0;
}
.seafile-comment-item .seafile-comment-info {
  padding-bottom: 0.5em;
  height: 3em;
  display: flex;
  justify-content: flex-start;
}
.seafile-comment-item .seafile-comment-info .reviewer-info {
  padding-left: 10px;
  max-width: 75%;
}
.seafile-comment-item .seafile-comment-info .review-time {
  font-size: 10px;
  color: #777;
}
.seafile-comment-item .seafile-comment-info .seafile-comment-dropdown {
  margin-left: auto;
}
.seafile-comment-item .seafile-comment-info .seafile-comment-dropdown button {
  border: none;
  box-shadow: none;
  background-color: #fff;
}
.seafile-comment-item .seafile-comment-info .seafile-comment-dropdown .seafile-comment-dropdown-btn {
  color: #999;
  background-color: transparent;
}
.seafile-comment-item .seafile-comment-info .seafile-comment-dropdown:hover .seafile-comment-dropdown-btn {
  color: #555;
}
.seafile-comment-item .seafile-comment-info .seafile-comment-dropdown button:hover,
.seafile-comment-item .seafile-comment-info .seafile-comment-dropdown button:focus {
  border: none;
  box-shadow: none;
  background-color: #eee;
}
.seafile-comment-item .seafile-comment-content {
  margin-left: 42px;
}
.seafile-comment-item .seafile-comment-content ol,
.seafile-comment-item .seafile-comment-content ul,
.seafile-comment-item .seafile-comment-content li {
  margin-left: 10px;
}
.seafile-comment-item .seafile-comment-content table,
.seafile-comment-item .seafile-comment-content th,
.seafile-comment-item .seafile-comment-content td {
  border: 1px solid #333;
}
.seafile-comment-item-resolved {
  background-color: #e6ffed;
}
.seafile-comment-footer {
  background-color: #fafaf9;
  padding: 10px;
  border-top: 1px solid #e5e5e5;
  min-height: 182px;
}
.seafile-edit-comment .edit-comment-input {
  border: 1px solid #e6e6dd;
  padding: 5px;
  width: 23em;
  min-height: 90px;
  border-radius: 5px;
  background-color: #fff;
}
.seafile-comment-footer .submit-comment {
  margin-top: 5px;
  padding: 0.35rem 0.5rem;
}
.seafile-edit-comment .comment-btn {
  height: 28px;
}
@media (max-width: 768px) {
  .seafile-comment-toggle-resolved {
    width: 100%;
  }
}
.detail-comments {
  border-left: 0;
}
.detail-comments .seafile-comment-list {
  margin-top: 0;
}
.detail-comments .seafile-comment-footer {
  min-height: 175px;
}
.detail-comments .seafile-edit-comment .edit-comment-input {
  width: 100%;
}
