.share-dialog .share-dialog-content {
    padding: 0;
    min-height: 27rem;
    display: flex;
    flex-direction: column;
}
@media (min-width: 768px) {
  .share-dialog .share-dialog-content {
    flex-direction: row;
  }
}

.share-dialog-content .share-dialog-side {
    display: flex;
    flex: 0 0 22%;
    padding: 1rem;
    border-bottom: 1px solid #eee;
}
@media (min-width: 768px) {
  .share-dialog-content .share-dialog-side {
    padding: 1rem 0 0 1rem;
    border: 0;
    border-right: 1px solid #eee;
  }
  .share-dialog-side .nav {
    flex-direction: column;
  }
}

.share-dialog-side .nav-pills .nav-item .nav-link {
    width: 100%;
}

.share-dialog-content .share-dialog-main {
    display: flex;
    flex: 0 0 78%;
    padding: 1rem;
}

.share-dialog-content .share-dialog-main .tab-content {
    flex: 1;
}

.share-dialog-content .share-dialog-main .tab-pane {
    height: 100%;
    overflow: auto;
}

.share-list-container {
    max-height: 18rem;
    min-height: 10rem;
}

.share-dialog-content label {
    padding: 0.5rem 0 0.25rem;
}

.share-dialog-content label.form-check-label {
    padding: 0.25rem 0;
}

.link-operation-content {
    margin-left: 1.25rem;
}

.link-operation-icon {
    color: #9aa0ac;
}

.tip {
    color: #808080;
    margin-bottom: 1rem;
}

.generate-share-link .passwd,
.generate-upload-link .passwd {
    width: 60%;
}

.generate-share-link .permission {
    margin-left: 2.5rem;
}

.generate-link-btn {
    margin-top: 1.125rem;
}

.share-dialog .nav .nav-item .nav-link {
    padding: 0.3125rem 1rem 0.3125rem 0.25rem;
}

input.expire-input {
    display: inline-block;
    width: 4rem;
    height: 1.5rem;
    padding: 0.25rem 0.25rem;
    margin: 0 0.25rem 0 1.25rem;
}

.expire-input-border {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    height: 2.375rem!important;
    padding: 0.375rem 0.75rem!important;
}

.share-dialog-main .permission-editor .permission-editor__menu {
  width: 240px;
}

.expir-span {
    border: 1px solid rgba(0, 40, 100, 0.12);
    border-left: none;
    font-size: 0.9375rem;
    margin-left: -5px;
    padding: 0px 8px;
    height: 2.375rem;
    display: inline-block;
    text-align: center;
    position: absolute;
    top: 4px;
    min-width: 2.375rem;
    line-height: 2.25;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}
